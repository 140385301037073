import { styled } from '@joovence/ui';

interface RootStyledProps {
  isJoovence: boolean;
}

export const RootStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isJoovence'
})<RootStyledProps>(({ theme, isJoovence }) => ({
  maxWidth: theme.breakpoints.values.sm,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: isJoovence ? undefined : 'center',

  margin: '0 auto',
  padding: isJoovence ? theme.spacing(0, 2) : theme.spacing(5, 2),

  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,

  header: {
    width: '100%',
    padding: theme.spacing(6, 4),

    textAlign: 'center'
  },

  main: {
    width: '100%'
  },

  footer: {
    width: '100%',
    marginTop: 'auto',
    padding: theme.spacing(6, 0, 4, 0),

    color: theme.palette.grey.A700,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',

    a: {
      color: theme.palette.grey.A700,
      textDecoration: 'none'
    },

    p: {
      margin: 0
    }
  },

  'h1, h2, h3': {
    margin: 0,
    padding: 0,

    textAlign: 'center'
  },

  h1: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px'
  },

  h2: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '24px'
  },

  form: {
    width: '100%',

    marginTop: theme.spacing(2)
  }
}));
