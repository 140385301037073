import { FC, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderUI, Emotion, MUI } from '@joovence/ui';

import { emotionCache, IsJoovenceThemeContext } from './helpers';

interface ThemeProviderProps {
  children: ReactNode;
  isJoovence: boolean;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  isJoovence
}) => {
  return (
    <Emotion.CacheProvider value={emotionCache}>
      <ThemeProviderUI isMonochrome={!isJoovence}>
        <MUI.CssBaseline />
        <IsJoovenceThemeContext.Provider value={isJoovence}>
          {children}
        </IsJoovenceThemeContext.Provider>
      </ThemeProviderUI>
    </Emotion.CacheProvider>
  );
};
