import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/nextjs';
import {
  ErrorBoundary as ErrorBoundaryUI,
  ErrorBoundaryLogger
} from '@joovence/ui';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  const { t } = useTranslation('common');

  const fallback = <h1>{t('common.errorBoundary')}</h1>;

  const logger: ErrorBoundaryLogger = (error) => {
    Sentry.captureException(error);
  };

  return (
    <ErrorBoundaryUI fallback={fallback} logger={logger}>
      {children}
    </ErrorBoundaryUI>
  );
};
