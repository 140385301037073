import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar as JoovenceSnackbar } from '@joovence/ui';

import { RootState } from '@/store';
import { snackbarActions } from '@/store/snackbar';

export const Snackbar: FC = () => {
  const dispatch = useDispatch();
  const open = useSelector(({ snackbar }: RootState) => snackbar.open);
  const type = useSelector(
    ({ snackbar }: RootState) => snackbar.type || 'info'
  );
  const text = useSelector(({ snackbar }: RootState) => snackbar.text || '');

  const handleClose = () => {
    dispatch(snackbarActions.closeAlert());
  };

  return (
    <JoovenceSnackbar
      open={open}
      onClose={handleClose}
      text={text}
      type={type}
    />
  );
};
